/** @jsx jsx */
import { jsx } from "theme-ui";
import {useEffect, useState} from "react";
import FastAPIClient from "../../../../../auth/fast_api_auth";
import 'shaka-player/dist/controls.css';


const ShakaPlayer = (props) => {
    if (typeof window !== 'undefined') {
        const ShakaPlayer = require('shaka-player-react')
        return <ShakaPlayer {...props}/>
    }
    return null;
}

const LandingVideo = ({ videoID }) => {
  let videoURL = videoID ? videoID : null;
  let [signedURL, setSignedURL] = useState(0)

    const fetchSignedURL = async function(videoURL) {
        if (videoURL == null){
            return videoURL
        }

        let client = new FastAPIClient();
        let signedVideoURL = await client.signVideoURL(videoURL)
        return signedVideoURL?.token
    }

    useEffect(() => {
        fetchSignedURL(videoURL)
            .then(data => {
                setSignedURL(data)
            })
    },[])

    if (videoURL == null){
        return null;
    }

  if (videoURL != null) {
      let url = `https://videodelivery.net/${signedURL}/manifest/video.mpd?clientBandwidthHint=3`
      return (
          <section id="video" className="py-5 pb-8 text-center md:pt-24">
              <div className="container">
                  <div className="bg-black" style={{display: "contents"}}>
                      <p align="center">
                          <ShakaPlayer src={url} />
                      </p>
                  </div>
              </div>
          </section>
      );
  } else {
      return null
  }
};

export default LandingVideo;
